body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    background: #F5F5F5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    outline: none;
    text-decoration: none;
}

a:focus,
a:hover {
    outline: none;
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0 !important;
    list-style: none;
}

.container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
}

@media screen and (min-width: 1400px) {
    .container {
        max-width: 1300px;
    }
}

.primarybtn {
    display: inline-block;
    min-width: 95px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    outline: none;
    border-radius: 25px;
    font-size: 13px;
    line-height: 1;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 7px 15px;
    background: #6747CE;
    cursor: pointer;
    position: relative;
}

.primarybtn:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, .2);
}

.primarybtn:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .0);
    z-index: -1;
    border-radius: 25px;
}

.primarybtn:hover:after {
    background: rgba(0, 0, 0, .2);
}

.primarybtn:disabled {
    background: #D9D9D9 !important;
    cursor: no-drop;
}

.primarybtn:disabled:after {
    display: none;
}

.main-body {
    margin: 58px 0 0 0;
    padding: 25px 0;
}

/*================= Login Start =================*/
.base-sign {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.base-sign .form-block {
    margin: 0;
    padding: 0;
}

.base-sign .form-block .logo-block {
    margin: 0 0 35px 0;
    padding: 0;
    text-align: center;
}

.base-sign .form-block .logo-block h1 {
    font-size: 25px;
    font-weight: 700;
    color: #6747CE;
    margin: 0;
    padding: 0;
}

.base-sign .form-block .block {
    width: 500px;
    margin: 0;
    padding: 40px 45px;
    background: #fff;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
    border-radius: 6px;
}

.base-sign .form-block .heading-text {
    margin: 0;
    padding: 0 0 35px 0;
}

.base-sign .form-block .heading-text h2 {
    font-size: 25px;
    line-height: 1;
    color: #000;
    font-weight: 600;
    margin: 0;
    padding: 0 0 8px 0;
}

.base-sign .form-block .heading-text p {
    font-size: 16px;
    line-height: 1;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.base-form .input-box {
    margin: 0 0 25px 0;
    padding: 0;
}

.base-form .input-box label {
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 500;
    margin: 0;
    padding: 0 0 8px 0;
}

.base-form .input-box .form-control {
    width: 100%;
    height: 45px;
    font-size: 16px;
    line-height: 1;
    color: #000;
    font-weight: 500;
    margin: 0;
    padding: 0;
    padding-left: 12px;
    padding-right: 12px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
}

.base-form .input-box .form-control:focus {
    border: 1px solid #6747CE;
    box-shadow: 0 3px 10px rgba(103, 71, 206, .2);
}

.base-sign .form-block .base-form .btn-field {
    padding-top: 10px;
}

.base-sign .form-block .base-form .btn-field .primarybtn {
    width: 100%;
    height: 48px;
    background: #6747CE;
    font-size: 15px;
    font-weight: 700;
}

/*================= Login End =================*/


/*================== Header Start ==================*/
.header-nav {
    margin: 0;
    padding: 0;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.header-nav .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-nav .lt-nav {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.logo-block {
    margin: 0 45px 0 0;
    padding: 0;
}

.logo-block a {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: #412A8D;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.navigation {
    margin: 0;
    padding: 0;
}

.navigation ul li {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 45px 0 0;
}

.navigation ul li:last-child {
    padding-right: 0;
}

.navigation ul li a {
    display: inline-block;
    color: #000;
    margin: 0;
    padding: 8px 15px;
    position: relative;
}

.navigation ul li a:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
}

.navigation ul li:hover a {
    transform: scale(1.1);
    transition: 0.33s;
}

.navigation ul li.active a:after {
    background: #6747CE;
}

.profile-menu .MuiPaper-root {
    top: 55px !important;
}

.db-block {
    margin: 0;
    padding: 0;
    position: relative;
}

.db-block .dropdown-btn {
    border: none;
    box-shadow: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.db-block .dropdown-btn .db-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: #e0dede;
    border-radius: 50%;
    margin: 0 10px 0 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.db-block .dropdown-btn .db-box:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    z-index: -1;
}

.db-block .dropdown-btn:hover .db-box:before {
    background: #e0dede;
}

.db-block .dropdown-btn .db-box span {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.db-block .dropdown-btn .arrow-icon {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.db-block .dropdown-btn .arrow-icon img {
    width: 12px;
    height: auto;
}

.db-block .dropdown-box {
    position: absolute;
    min-width: 165px;
    top: 42px;
    right: 0;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, .2);
    border-radius: 7px;
    margin: 0;
    padding: 5px 0;
    z-index: 1;
}

.db-block .dropdown-box ul li {
    font-size: 15px;
    line-height: 1;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 13px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.db-block .dropdown-box ul li:last-child {
    font-weight: 500;
    border-bottom: none;
}

.db-block .dropdown-box ul li.logout-btn:hover {
    color: #6747CE;
    cursor: pointer;
}

.dropdown-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: none;
}

.dropdown-overlay.active {
    display: block;
}

/*================== Header End ==================*/

/*================== Configurations Start ==================*/
.element-info {
    margin: 0;
    padding: 0;
}

.element-info .tab-list {
    margin: 0 0 25px 0;
    padding: 0;
    text-align: center;
}

.element-info .tab-list ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 1px solid #6747CE;
    border-radius: 25px;
    overflow: hidden;
    background: #fff;
}

.element-info .tab-list ul li {
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    color: #6747CE;
    font-weight: 400;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    min-width: 185px;
    cursor: pointer;
}

.element-info .tab-list ul li:hover {
    background: #f3f0fc;
}

.element-info .tab-list ul li.active {
    background: #6747CE;
    color: #fff;
}

.tab-content {
    margin: 0;
    padding: 0;
}

.tab-content .tab-desc {
    margin: 0;
    padding: 0;
}

.tab-content .block {
    margin: 0 0 25px 0;
    padding: 20px 0;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
}

.tab-content .block .box {
    margin: 0;
    padding: 0 20px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.tab-content .block .box:first-child {
    min-width: 355px;
}

.tab-content .block .box:last-child {
    border-right: none;
}

.tab-content .block .box .up-text {
    margin: 0;
    padding: 0 0 25px 0;
}

.tab-content .block .box h1 {
    font-size: 30px;
    line-height: 1;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0 0 10px 0;
}

.tab-content .block .box .stg-text {
    font-size: 12px;
    line-height: 1;
    color: grey;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.tab-content .block .bottom-field {
    margin: 0;
    padding: 0;
    display: flex;
}

.tab-content .block .boxes {
    margin: 0;
    padding: 0 40px 0 0;
}

.tab-content .block .boxes:last-child {
    padding-right: 15px;
}

.tab-content .block .boxes .logo {
    margin: 0 0 10px 0;
    padding: 0;
}

.tab-content .block .boxes .logo img {
    width: auto;
    height: auto;
    max-height: 21px;
}

.tab-content .block .boxes p {
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.tab-content .block .box ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tab-content .block .box ol li {
    font-size: 14px;
    line-height: 1.3;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 0 15px 0;
}

.tab-content .block .box ol li:last-child {
    padding-bottom: 0;
}

.tab-content .block .box ol li .label-text {
    display: inline-block;
    color: #959595;
    margin: 0;
    padding: 0;
}

.tab-content .block .box ol li .value-text {
    margin: 0;
    padding: 0 0 0 5px;
}

.tab-content .block .box ol li:last-child .value-text {
    display: block;
    padding-left: 0;
}

.tab-content .block .box.sec-box ol li .value-text {
    display: block;
    padding-left: 0;
}

.tab-content .block .box.sec-box ol li .value-text.disabled {
    text-decoration: line-through;
    color: #999
}

.tab-content .block .box ol li p {
    font-size: 19px;
    line-height: 1.3;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.tab-content .block .box ol li p span {
    display: block;
}


.tab-content .block .box h4 {
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 0 20px 0;
}

.tab-content .block .box ul {
    margin: 0;
    padding: 0;
}

.tab-content .block .box ul li {
    display: inline-block;
    width: 33.3%;
    margin: 0;
    padding: 0;
    padding: 0;
}

.tab-content .block .box ul li .number-text {
    display: block;
    font-size: 35px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 0 5px 0;
}

.tab-content .block .box ul li p {
    font-size: 12px;
    line-height: 1;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.tab-content .block .box ul li p span {
    display: block;
}

.tab-content .block .box.rc-box {
    min-width: 450px;
}

.tab-content .block .box.sec-box {
    min-width: 328px;
}

.tab-content .block .box.ulrc-box {
    justify-content: flex-start;
}


.tab-content .block .btn-block {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.tab-content .block .btn-block .primarybtn {
    margin-bottom: 10px;
}

.tab-content .block .btn-block .primarybtn.delete-btn {
    margin-bottom: 0;
    background: #C00407;
}

.tab-content .tabcontent-hbs-sp .block .box.rc-box {
    min-width: 290px;
}


.checkbox-list {
    margin: 0;
    padding: 0;
    max-width: 300px;
}

.checkbox-list .form-group {
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 9px 12px 7px;
    background: #EDEDED;
    border-radius: 4px;
}

.checkbox-list .form-group .input-check {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-block .input-box .checkbox-list .form-group .check-label {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 0 !important;
    font-size: 13px;
    line-height: 1;
    color: #333;
    font-weight: 500;
}

.checkbox-list .form-group .check-label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background: url(./assets/images/checked-icon-wh.svg) no-repeat center;
    background-size: 17px;
    top: -2px;
}

.checkbox-list .form-group .input-check:checked+.check-label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background: url(./assets/images/checked-icon.svg) no-repeat center;
    background-size: 17px;
    top: -2px;
}


/*================== Configurations End ==================*/


/*================== Add Configurations Start ==================*/
.add-element {
    margin: 0;
    padding: 45px 65px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
}

.add-element .box {
    width: 50%;
    margin: 0;
    padding: 0;
    border-right: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
}

.add-element .box:last-child {
    border-right: none;
}

.add-element .box h2 {
    font-size: 27px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 0 30px 0;
}

.add-element .form-block {
    max-width: 450px;
}

.add-element .form-block .input-box {
    margin: 0 0 25px 0;
    padding: 0;
}

.add-element .form-block .input-box label {
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0 0 8px 0;
}

.add-element .form-block .input-box .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, .1);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.add-element .form-block .input-box .form-control:focus {
    border: 1px solid #6747CE;
    box-shadow: 0 3px 6px rgba(103, 71, 206, .2);
}

.add-element .form-block ul {
    margin: 0;
    padding: 0;
    max-width: 300px;
}

.add-element .form-block ul li {
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    color: #333;
    font-weight: 500;
    margin: 0 5px 5px 0;
    padding: 9px 12px;
    background: #EDEDED;
    border-radius: 4px;
}

.add-element .form-block ul li .icon {
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 0;
    vertical-align: middle;
    line-height: 0;
}

.add-element .form-block ul li .icon img {
    width: 17px;
    height: auto;
}

.add-element .form-block .btn-box {
    margin: 0;
    padding: 0;
}

.add-element .form-block .btn-box .primarybtn {
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 145px;
}

.add-element .desc-box {
    display: flex;
    justify-content: center;
}

.add-element .desc-box p {
    max-width: 400px;
    font-size: 14px;
    line-height: 1.7;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.search-field {
    margin: 0 0 30px 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.search-field label {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.search-field .input-box {
    margin: 0 0 0 15px;
    padding: 0;
    position: relative;
}

.search-field .input-box .form-control {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, .1);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 30px;
    box-sizing: border-box;
}

.search-field .input-box .form-control:focus {
    border: 1px solid #6747CE;
    box-shadow: 0 3px 6px rgb(103 71 206 / 20%);
}

.search-field .input-box .search-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border: none;
    box-shadow: none;
    outline: none;
    background: none;
    line-height: 0;
    cursor: pointer;
}

.field-table {
    margin: 0;
    padding: 0;
}

.field-table .ant-collapse .ant-collapse-header {
    font-size: 15px;
    font-weight: 500;
}

.field-table table {
    width: 100%;
}

.field-table table tr td {
    padding: 5px;
}

.field-table table tr td .ant-collapse {
    width: 750px;
}

.field-table table tr:hover {
    background: #f4f4f4;
}


.field-table table tr td:first-child {
    min-width: 500px;
}

.field-table table tr td .form-control {
    height: 32px;
    border: 1px solid rgba(0, 0, 0, .1);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.field-table table tr td .form-control:focus {
    border: 1px solid #6747CE;
    box-shadow: 0 3px 6px rgb(103 71 206 / 20%);
}


.btn-box .primarybtn.create-cnt-btn {
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 145px;
    margin-top: 30px;
}



/*================== Add Configurations End ==================*/

/*================== Table Start ==================*/
.table-info {
    margin: 0;
    padding: 0;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.table-info table {
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-info table thead tr th {
    font-size: 13px;
    line-height: 1;
    color: #000;
    font-weight: 600;
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.table-info table tbody tr td {
    font-size: 13px;
    line-height: 1;
    color: #333;
    font-weight: 400;
    margin: 0;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.table-info table tbody tr td .primarybtn {
    min-width: 85px;
}

.table-info table tbody tr td .primarybtn.delete-btn {
    margin-left: 15px;
    background: #CE0000;
}

.table-info table tbody tr td .color-text {
    font-weight: 700;
    text-transform: uppercase;
}

.table-info table tbody tr td .color-text.gr-color {
    color: #00843D;
}

.table-info table tbody tr td .color-text.rd-color {
    color: #FF0000;
}

.table-info table tbody tr td.succes {
    background: #E1FCE0;
}

.table-info table tbody tr td.error {
    background: #FCE0E0;
}

/*================== Table End ==================*/

/*================== Snyc History Start ==================*/
.filter-element {
    margin: 0 0 25px 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.filter-element .heading-text {
    margin: 0;
    padding: 0;
}

.filter-element .heading-text label {
    font-size: 15px;
    line-height: 1;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.filter-element .form-filter {
    flex: 1;
    margin: 0 0 0 25px;
    padding: 0;
}

.bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 30px;
    text-align: right;
}

.bottom-button .primarybtn.add-btn {
    min-width: 185px;
    height: 40px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
}

/*================== Snyc History End ==================*/

.statusbox {
    float: left;
    width: 10px;
    height: 10px;
    margin-top: 7px;
    margin-right: 10px;
    border-radius: 50%;
}

.statusbox.enabled {
    background-color: #00f070;
}

.statusbox.disabled {
    background-color: #eb0004;
}

/*================== create admin page css ==================*/

/* .center{
    width: 30%;
    position: absolute;
    left: 35%;
    top: 10%;
    
} */

.adminpage-btn {
    margin-left: 12rem;

}

.form-outliner {
    padding: 4rem;
    box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, .06);
    ;

    width: 40%;
    height: auto;
    position: absolute;
    left: 30%;
    top: 20%;

    border-radius: 15px;
}

.form-outliner1 {
    padding: 1rem 4rem;
    box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, .06);
    ;

    width: 40%;
    height: 25rem;
    position: absolute;
    left: 30%;
    top: 20%;

    border-radius: 15px;
}

.btn-top-margin {
    margin-top: 5rem;
}

.hidden {
    display: none;
}

.dropdown-header-menu {
    display: inline;
    flex-direction: column;
}

.menu {
    display: none;
    flex-direction: column;
    position: absolute;


    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.menu a {
    padding: 1px 10px;
    text-decoration: none;
    display: block;
}

.dropdown-header-menu:hover .menu {
    display: flex;

}

/* *****table-design-mapping**** */

.mapping-btn-sumit-company {
    margin-top: 10px;
    border: none;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #6747CE;
    color: #fff;
    cursor: pointer;
}

.mapping-btn-sumit-company:hover {
    background-color: #412A8D;
}

.data-table-1 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 14rem;
    left: 13rem;


    overflow-y: scroll;

}

.data-table-2 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 39rem;
    left: 13rem;


    overflow-y: scroll;

}

.data-table-3 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 64rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-4 {
    width: 70%;
    height: 20rem;
    position: absolute;
    top: 89rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-5 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 114rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-6 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 139rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-7 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 164rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-8 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 189rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-9 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 214rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-10 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 239rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-11 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 264rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-12 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 289rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-13 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 314rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-14 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 339rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-15 {
    width: 70%;

    height: 20rem;
    position: absolute;
    top: 364rem;
    left: 13rem;


    overflow-y: scroll;
}

.data-table-label-name {
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 5px;
}

#main-table {
    border-collapse: collapse;
    width: 100%;
}




#table-data td,
#table-data th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

#main-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.btn-sumit-table {
    position: fixed;
    top: 48rem;
    right: 45px;
    border: none;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #6747CE;
    color: #fff;
    cursor: pointer;
}

.btn-sumit-table:hover {
    background-color: #412A8D;
}

.blank-div {
    position: absolute;
    top: 390rem;
    left: 20rem;
    height: 1px;
    width: 1px;
}

/* ====list client==== table === */

.table-info-list-client {
    height: 40rem;
    /* overflow: hidden; */
    overflow-y: scroll;
}

.table-info-list-client::-webkit-scrollbar {
    display: none;
  }

.list-client-btn-edit {
    border: none;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #6747CE;
    color: #fff;
    cursor: pointer;
}

.list-client-btn-add {
    border: none;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #6747CE;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 40px;
    bottom: 20px;
}



.list-client-btn-add:hover,
.list-client-btn-edit:hover {
    background-color: #412A8D;
}